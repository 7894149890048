<template>
  <b-card title="">
    <!-- <p>dd {{ cloudid }}pp</p> -->
    <b-row class="my-1">
      <b-col><h3 class="text-primary">Create Application</h3></b-col>
      <b-col>
        <div class="d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mr-1"
            @click="gotoApplicationList()"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <validation-observer #default="{ invalid }">
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="6">
                <b-form-group label="Application Name" label-for="v-first-name">
                  <validation-provider
                    #default="{ errors }"
                    name="Application Name"
                    rules="required"
                  >
                    <b-form-input
                      id="v-first-name"
                      placeholder="Enter Application Name"
                      v-model="applicationName"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Application Link" label-for="v-first-name">
                  
                  <b-input-group>
                    <template #prepend>
                      <b-form-select
                        v-model="protocol"
                        :options="protocolOptions"
                      ></b-form-select>
                    </template>
                    <b-form-input
                      v-model="applicationLink"
                      placeholder="Enter Application Link"
                    ></b-form-input>
                  </b-input-group>
                  
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Application Details"
                  label-for="v-first-name"
                >
                  <!-- <label for="textarea-default">Textarea</label> -->
                  <b-form-textarea
                    v-model="applicationDetails"
                    id="textarea-default"
                    rows="3"
                  />
                  <p class="p-0 m-0 fs-10">
                    <span class="text-danger">*</span>app details is optional
                  </p>
                </b-form-group>
              </b-col>
              <div class="my-2"></div>
              <b-col cols="6">
                <b-form-group label="Cloud Profile" label-for="v-first-name">
                  <!-- <validation-provider
                    #default="{ errors }"
                    name="Groups"
                    rules="required"
                  > -->
                  <!-- <v-select
                    v-model="cloudProfile"
                    :options="cloudProfileOptions"
                    label="name"
                    taggable
                    @change="fetchFirewall()"
                  /> -->

                  <b-form-select
                    v-model="cloudProfile"
                    @change="firewallUnderCloud()"
                  >
                    <b-form-select-option
                      v-for="cloud in cloudProfileOptionss"
                      :key="cloud.id"
                      :value="cloud"
                      >{{ cloud.name }}</b-form-select-option
                    >
                  </b-form-select>
                  <!-- {{ cloudProfile }} -->
                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider> -->
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Firewalls" label-for="v-first-name">
                  <!-- <validation-provider
                    #default="{ errors }"
                    name="Application List"
                    rules="required"
                  > -->
                  <v-select
                    v-model="firewalls"
                    multiple
                    :options="firewall_options"
                    label="name"
                    taggable
                  />
                  <!-- <p class="p-0 m-0 fs-10">
                    <span class="text-danger">*</span>only select one firewall
                  </p> -->
                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider> -->
                </b-form-group>
                <!-- {{ firewalls }} -->
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <b-form-checkbox
                    id="checkbox-10"
                    name="checkbox-10"
                    :checked="publicApplication"
                    v-model="publicApplication"
                  >
                    Public Application
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <b-form-checkbox
                    id="checkbox-11"
                    name="checkbox-11"
                    :checked="autoEnroll"
                    v-model="autoEnroll"
                  >
                    Automatic Enroll
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-row class="ty-3">
                  <b-col class="col-12">
                    <h3 class="text-primary d-flex align-items-center my-2">
                      <!-- <feather-icon
                        icon="FileTextIcon"
                        size="20"
                        class="mr-0 mr-sm-50"
                      /> -->
                      Ports
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        type="submit"
                        class="btn-icon rounded-circle ml-2"
                        @click="addField(k)"
                      >
                        <feather-icon icon="PlusIcon" />
                      </b-button>
                    </h3>
                    <!-- <p>Attach All Mandatory Documents.</p>
                    <div v-for="doc in selectDocument" :key="doc">
                      <p class="my-2">
                        {{ doc.document_name
                        }}<b-form-file
                          v-model="file"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                        />Upload {{ doc.file_type }} file
                      </p>
                      <hr />
                    </div> -->
                  </b-col>
                  <b-col class="col-12">
                    <!-- <h4>
                     <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        type="submit"
                        class="btn-icon rounded-circle ml-2"
                        @click="addField(k)"
                      >
                        <feather-icon icon="PlusIcon" />
                      </b-button>
                    </h4> -->
                    <!-- <b-form-group label-for="uploadFile">
                    <b-form-file
                      v-model="file"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    />
                  </b-form-group> -->
                  </b-col>
                  <b-col>
                    <div
                      id="one"
                      class="d-flex"
                      v-for="(input, k) in portNumbers"
                      :key="k"
                    >
                      <!-- :key="`phoneInput-${index}`" -->
                      <div class="col-6 d-flex align-items-center w-span mb-1">
                        <validation-provider
                          #default="{ errors }"
                          name="From Port"
                          rules="required"
                        >
                          <b-form-input
                            v-model="input.from_port"
                            class="col-11"
                            placeholder="from port"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>

                      <div class="d-flex align-items-center col-6 w-span mb-1">
                        <!-- input.phone -->
                        <validation-provider
                          #default="{ errors }"
                          name="To Port"
                          rules="required"
                        >
                          <b-form-input
                            v-model="input.to_port"
                            class="col-11 w-100"
                            placeholder="to port"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <b-button
                          v-show="portNumbers.length > 1"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="danger"
                          type="submit"
                          class="btn-icon rounded-circle ml-2 mb-1"
                          @click="removeField(k)"
                        >
                          <feather-icon icon="MinusIcon" />
                        </b-button>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>

              <!-- submit and reset -->
              <b-col cols="12">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  @click="createApplication()"
                  :disabled="invalid"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  BPagination,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea,
  BFormFile,
  BForm,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    vSelect,
    BFormFile,
    BForm,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      protocol: "http://",
      protocolOptions: [
        { value: "http://", text: "http" },
        { value: "https://", text: "https" },
      ],
      applicationName: "",
      applicationLink: "",
      applicationDetails: "",
      firewalls: [],
      firewallOptions: [],
      firewall_options: [],
      cloudProfile: "",
      cloudProfileOptionss: [],
      cloudProfileOptions: [],
      cloudid: "",
      portNumbers: [{ to_port: "", from_port: "" }],
      // fromPort:"",
      // toPort:"",
      phone: "",
      publicApplication: false,
      autoEnroll: false,
      //   tenantid: "",
      //   accesstoken: "",
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getCloudList();
    // this.fetchFirewall();
    // this.cloudid = this.$route.params.id;
    // (this.tenantid = this.$store.getters.tenantid),
    //   (this.accesstoken = this.$store.getters.token),
    //   console.log(this.tenantid);
  },
  methods: {
    getCloudList: function () {
      const options = {
        method: "GET",
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("JWTtoken")}`,
        //   "content-type": "application/json",
        // },
        url: process.env.VUE_APP_BASEURL + `cloudprofile/cloudprofile`,
      };
      var self = this;
      this.$http(options)
        .then((res) => {
          console.log(res.data);
          this.cloudProfileOptionss = res.data;
          // this.cloudProfileOptionss.forEach((ele) => {
          //   this.cloudProfileOptions.push(ele);
          // });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    firewallUnderCloud(value) {
      this.fetchFirewall(this.cloudProfile.id);
    },
    fetchFirewall(cloudid) {
      this.firewall_options = [];
      const options = {
        method: "GET",
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("JWTtoken")}`,
        //   "content-type": "application/json",
        // },
        url:
          process.env.VUE_APP_BASEURL +
          `firewall/firewall/?cloud_profile=${cloudid}`,
      };
      this.$http(options).then((res) => {
        this.items = res.data;
        this.firewallOptions = res.data;
        this.firewallOptions.forEach((ele) => {
          delete ele["cloud_profile_data"];
          delete ele["applications"];
          this.firewall_options.push(ele);
        });
        console.log(this.firewall_options);
      });
    },
    createApplication: function () {
      let data = {
        application_name: this.applicationName,
        application_link:
          this.applicationLink == ""
            ? ""
            : this.protocol + this.applicationLink,
        description: this.applicationDetails,
        firewalls: this.firewalls,
        is_public_application: this.publicApplication,
        is_automatic_enroll: this.autoEnroll,
        // port: b,
        // port: b.replace(/\\/g, ""),
        // port:JSON.stringify(this.phoneNumbers),
        port: JSON.parse(JSON.stringify(this.portNumbers)),

        // port: [{ to_port:this.phoneNumbers.to_ports,from_port:this.phoneNumbers.from_ports}],
        // to_port:this.phoneNumbers.to_ports,
        // from_port:this.phoneNumbers.from_ports,
      };
      // str = this.phoneNumbers.replace(/\\/g, '');
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `application/applications/`,
      };
      var self = this;
      this.$http(options).then((res) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Created Successfully",
            icon: "EditIcon",
            variant: "success",
          },
        });
        // this.$store.dispatch('getMasters')
        self.$router.push({ name: "home" });
      });
    },
    gotoApplicationList: function () {
      this.$router.push({ name: "home" });
    },
    addField(value, fieldType) {
      this.portNumbers.push({
        to_port: "",
        from_port: "",
      });
    },
    addUrlField(value) {
      this.addUrl.push({
        add_url: "",
      });
    },
    removeUrlField(index, fieldType) {
      // fieldType.splice(index, 1);
      this.addUrl.splice(index, 1);
      // fieldType.splice(index, 1);
    },
    removeField(index, fieldType) {
      // fieldType.splice(index, 1);
      this.portNumbers.splice(index, 1);
      // fieldType.splice(index, 1);
    },
  },
};
</script>
<style  lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.fs-10 {
  font-size: 10px;
}
.w-span span {
  width: 100% !important;
}
</style>
 